
import PropTypes from 'prop-types';
import React from "react";
import CookieConsent from "react-cookie-consent";

import Header from './organisms/header/header';
import Footer from './organisms/footer/footer';

import '../styles/variables.css';
import '../styles/global.css';
import styles from './layout.module.css';

const menuItems = [
  {
    active: false,
    href: 'https://www.xing.de',
    target: '_blank',
    title: 'Xing'
  },
  {
    active: false,
    href: 'https://www.linkedin.com',
    target: '_blanc',
    title: 'linkedIn'
  },
  {
    active: false,
    href: 'https://www.github.com',
    target: '_blanc',
    title: 'github'
  }
];

const Layout = ({ children, logoText }) => (
  <div className={styles.layout}>
    <Header className={styles.header} logoText={logoText} menuItems={menuItems} />
    <main style={{flex: 1}}>{children}</main>
    <Footer className={styles.footer} />
    <CookieConsent
      location="bottom"
      buttonText="Ich Akzeptiere"
      cookieName="gatsby-gdpr-google-analytics"
      onAccept={() => window.location.reload()}
      style={{ background: 'var(--colorGrey1)', display: 'flex' }}
      buttonStyle={{ order: 2, backgroundColor: 'var(--colorDarkBlue)', color: 'var(--colorLight)', fontSize: "var(--fontSize)" }}
      expires={150}
      enableDeclineButton={true}
      declineButtonText='Lieber nicht'
      declineButtonStyle={{ order: 3, backgroundColor: 'var(--colorGrey3)', fontSize: 'var(--fontSize)' }}
    >
      Zur stetigen Verbesserung meiner Webeite setze ich Google Analytics als Analysewerkzeug ein.
      Alle Daten werden anonymisiert. Weitere Informationen zur Datenverarbeitung findet Ihr in meiner <a href="/datenschutz">Datenschutzerklärung</a>.
    </CookieConsent>
  </div>
)

Layout.propTypes = {
  logoText: PropTypes.string
}

Layout.defaultProps = {
  logoText: 'LeanWorks'
}

export default Layout;
