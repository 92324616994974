import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby";

import IconNav  from '../../molecules/iconNav/iconNav';

import Xing     from '../../../images/xing.svg';
import Linkedin from '../../../images/linkedin.svg';
import Github   from '../../../images/github.svg';

import styles from "./footer.module.css"

const Footer = ({ className }) => (
  <div className={[styles.footer, className].join(' ')}>
      <IconNav className={styles.socialNav}
          menuItems={[
            {
              title: 'Visit me at Xing',
              href: 'https://www.xing.com/profile/Bjoern_Biege',
              svg: Xing,
              target: '_blank'
            },{
              title: 'Visit us at Linkedin',
              href: 'https://www.linkedin.com/in/bj%C3%B6rn-biege-637aa8106/',
              svg: Linkedin,
              target: '_blank'
            },{
              title: 'Visit me at Github',
              href: 'https://github.com/freesh',
              svg: Github,
              target: '_blank'
            }
          ]}
        />
      <footer>
          <div>&copy; 2022 LeanWorks</div>
          <nav className={styles.nav}>
              <Link className={styles.navItem} to="/impressum">Impressum</Link>
              <Link className={styles.navItem} to="/datenschutz">Datenschutz</Link>
          </nav>
      </footer>
  </div>
)

Footer.propTypes = {
  className: PropTypes.string
}

Footer.defaultProps = {
  className: ''
}

export default Footer;
