import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

//import Navigation from "./navigation/navigation";
import IconNav    from '../../molecules/iconNav/iconNav';

import styles   from './header.module.css';
import Xing     from '../../../images/xing.svg';
import Linkedin from '../../../images/linkedin.svg';
import Github   from '../../../images/github.svg';

const Header = ({ children, logoText, menuItems, className }) => {

  return (
    <div className={[styles.wrapper, className].join(' ')}>
        <header className={styles.header}>
            <div className={styles.logo}><Link to="/#">{logoText}</Link></div>
            <div className={styles.content}>{children}</div>
            <IconNav
              className={styles.socialNav}
              menuItems={[
                {
                  title: 'Visit me at Xing',
                  href: 'https://www.xing.com/profile/Bjoern_Biege',
                  svg: Xing,
                  target: '_blank'
                },{
                  title: 'Visit us at Linkedin',
                  href: 'https://www.linkedin.com/in/bj%C3%B6rn-biege-637aa8106/',
                  svg: Linkedin,
                  target: '_blank'
                },{
                  title: 'Visit me at Github',
                  href: 'https://github.com/freesh',
                  svg: Github,
                  target: '_blank'
                }
              ]}
            />
            {/* <Navigation menuItems={menuItems} /> */}
        </header>
    </div>
  )
}

Header.propTypes = {
  logoText: PropTypes.string,
  menuItems: PropTypes.array,
  className: PropTypes.string
}

Header.defaultProps = {
  logoText: '',
  menuItems: [],
  className: ''
}

export default Header;
